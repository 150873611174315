import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

const Header = ({ activeTab }) => (
<nav className="navbar navbar-expand-lg navbar-light bg-transparent transparent">
  <Link className="navbar-brand" to="/">
    <StaticImage height={50} src="../assets/images/logothing.png" alt="Regency Home Fashions Logo"/>
    <StaticImage height={50} src="../assets/images/logo.png" alt="Regency Home Fashions Logo"/>
  </Link>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item">
        <Link className="nav-link" to="/windowtreatments">Window Treatments</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/blinds">Blinds</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/cornices">Cornices</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/reupholstery">Reupholstery</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/specialty">Specialty</Link>
      </li>

      <li className="nav-item active">
        <Link className="nav-link" to="/about">About Us</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/contact">Contact</Link>
      </li>
    </ul>
  </div>
</nav>
);

export default Header;
