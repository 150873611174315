import * as React from "react";
import * as styles from "./button.module.css";
import classNames from "classnames";


const Button = ({ children }) => (
  <button className={classNames(styles.button)}>{ children }</button>
);

export default Button;
