import * as React from "react";
import classNames from 'classnames';

import * as styles from "./clients.module.css";

import Battleground from "../assets/images/clients/battleground.jpg";
import Avanti from "../assets/images/clients/avanti.png";
import Cinderella from "../assets/images/clients/cinderella.png";
import GMFJ from "../assets/images/clients/gmfj.png";
import Littman from "../assets/images/clients/littman.png";
import Castle from "../assets/images/clients/castle.svg";

const Clients = () => (
<div className={classNames(styles.clientContainer, "container")}>
  <div className="row">
    <div className="col-12">
      <h3>Prominent Clients</h3>
      <span className={classNames(styles.line)}></span>
    </div>
  </div>
  <div className="row">
    <div className="col-12">
      <div className="d-flex align-items-center flex-wrap justify-content-center">
        <div className={classNames(styles.clientItem)}>
          <img height="150" src={Battleground} />
        </div>
        <div className={classNames(styles.clientItem)}>
          <img height="80" src={Avanti} />
        </div>
        <div className={classNames(styles.clientItem)}>
          <img height="100" src={Cinderella} />
        </div>
        <div className={classNames(styles.clientItem)}>
          <img height="100" src={GMFJ} />
        </div>
        <div className={classNames(styles.clientItem)}>
          <img height="30" src={Littman} />
        </div>
        <div className={classNames(styles.clientItem)}>
          <img height="80" src={Castle} />
        </div>
      </div>
    </div>
  </div>
</div>
);

export default Clients;