import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => (
<div className="footer-container container-fluid">
  <div className="row">
    <div className="col-12">
      <div className="container">
        <hr />
        <div className="row">
          <div className="col-12">
            <h2>Contact</h2>
            <small>@regencyhomefashions</small>
            <br/>
            <br/>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="pb-0 mb-0"><span className="font-weight-bold">Phone: </span>(732) 780-9277</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <a href="mailto:regencyhome@aol.com">
              <p className="font-weight-bold d-inline">
                Email:{' '}
              </p>
              <p className="d-inline rhf-link">
                regencyhome@aol.com
              </p>
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
              <br />
              <a className="fb-icon" href="https://www.facebook.com/Regencyhomefashions" target="_blank">
                <p className="d-inline">
                  <i className="fab fa-facebook-square fa-2x"></i>
                </p>
              </a>

              <a className="insta-icon" href="https://www.instagram.com/regencyhomefashions/" target="_blank">
                <p className="d-inline">
                  <i className="fab fa-instagram fa-2x"></i>
                </p>
              </a>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <br />
            <small>© {new Date().getFullYear()} Regency Home Fashions All Rights Reserved</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);

export default Footer;
