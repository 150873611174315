import * as React from "react";
import classNames from 'classnames';

import * as styles from "./reviews.module.css";

const Reviews = () => (
  <div className={classNames(styles.testimonialsContainer, "container")}>

  <a
    target="_blank"
    href="https://www.yelp.com/biz/regency-home-fashions-marlboro?hrid=Q7nCnov55zLyP9jpSUyKBA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)"
  >
    <div className={classNames(styles.testimonial, "row")}>
      <div className={classNames(styles.stars, "col-md-2")}>
        <i className={classNames(styles.yelpIcon, "fab fa-yelp fa-5x")}></i>
        <br />
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
      </div>

      <div className="col-md-6">
        <h5>Joseph C.</h5>
        <p>
          I have used this company for several rooms in my home.
          They use only quality fabrics and dealing with the owner
          Avi is always a pleasure.  I would definitely recommend Regency.
        </p>
      </div>
    </div>
  </a>


  <a
    className="smallOnly"
    target="_blank"
    href="https://www.yelp.com/biz/regency-home-fashions-marlboro?hrid=XLgtiaUJ6Hw_jGbQSh7tqg&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)"
  >
    <div className={classNames(styles.testimonial, "row")}>

      <div className={classNames(styles.stars, "col-md-2")}>
        <i className={classNames(styles.yelpIcon, "fab fa-yelp fa-5x")}></i>
        <br />
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
      </div>

      <div className="col-md-6 offset-md-4">
        <h5>Marjorie W.</h5>
        <p>
          Just used Regency to repair two custom made Roman shades.
          They did a great job in a timely manner and were prompt to
          return phone calls. Would use them again and recommend them
          to others.
        </p>
      </div>

    </div>
  </a>

  <a
    className="mediumOnly"
    target="_blank"
    href="https://www.yelp.com/biz/regency-home-fashions-marlboro?hrid=XLgtiaUJ6Hw_jGbQSh7tqg&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)"
  >
    <div className={classNames(styles.testimonial, "row")}>

      <div className="col-md-6  offset-md-4">
        <h5>Marjorie W.</h5>
        <p>
          Just used Regency to repair two custom made Roman shades.
          They did a great job in a timely manner and were prompt to
          return phone calls. Would use them again and recommend them
          to others.
        </p>
      </div>

      <div className={classNames(styles.stars, "col-md-2")}>
        <i className={classNames(styles.yelpIcon, "fab fa-yelp fa-5x")}></i>
        <br />
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
        <i className={classNames(styles.yelpStarIcon, "fas fa-star fa-1x")}></i>
      </div>

    </div>
  </a>


</div>
);

export default Reviews;