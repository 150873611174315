import * as React from "react";
import * as styles from "./hero.module.css";
import classNames from "classnames";

import Logo from "../assets/images/logo.png";
import LogoPic from "../assets/images/logothing.png";
import Button from "./button";
import { Link } from "gatsby";

const Hero = () => (
  <div className={classNames(styles.heroContainer)}>
    <div className={classNames(styles.hero)}>
      <div className={classNames("container")}>
        <div className="row">
          <div className="col">
            <div className={classNames(styles.heroContent)}>
              {/*
              <div className={classNames(styles.logo)}><img height={100} src={LogoPic} /></div>
              */}
            <div className={classNames(styles.logo)}><img src={Logo} /></div>
              <h3>Providing home fashion excellence to the Tri-State area for over 40 years.</h3>
              <br />
              <Link to="/contact"><Button>Contact Us</Button></Link>
              <hr className={classNames(styles.hr)} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={classNames(styles.heroTriangle)}></div>
  </div>
);

export default Hero;
