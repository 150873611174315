import * as React from 'react';
import classNames from 'classnames';

import * as styles from "./perks.module.css";


const Perks = () => (
  <div className={classNames(styles.perksContainer, "container-fluid")}>
  <div className="row">
    <div className="col-12">
      <div className="container">
        <div className="row">

          <div className={classNames(styles.perkItem, "col-md-4 col-sm-12")}>
            <div className="row">
              <div className="col-12 text-center">
                <i className={classNames(styles.perkIcon, "fas fa-dollar-sign fa-5x")}></i>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center">
                <br />
                <h3>Always the lowest price</h3>
                <br />
              </div>
            </div>
          </div>

          <div className={classNames(styles.perkItem, "col-md-4 col-sm-12")}>
            <div className="row">
              <div className="col-12 text-center">
              <i className={classNames(styles.perkIcon, "fas fa-ruler fa-5x")}></i>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center">
                <br />
                <h3>Free measurement and installation</h3>
                <br />
              </div>
            </div>
          </div>


          <div className={classNames(styles.perkItem, "col-md-4 col-sm-12")}>
            <div className="row">
              <div className="col-12 text-center">
                <i className={classNames(styles.perkIcon, "fas fa-shuttle-van fa-5x")}></i>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center">
                <br />
                <h3>We come to you</h3>
                <br />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
)

export default Perks;