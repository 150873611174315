import * as React from "react";

import Header from "../components/header";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Reviews from "../components/reviews";
import Clients from "../components/clients";
import Perks from "../components/perks";

const IndexPage = () => (
  <React.Fragment>
    <Header />
    <Hero />
    <Reviews />
    <Perks />
    <Clients />
    <Footer />
  </React.Fragment>
);

export default IndexPage;
